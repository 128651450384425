import React from 'react';

function HashoutTechEmployeeTestimonial() {
    return (
        <div style={{height: "100vh"}}>
            <object data="../../Hashout-Tech-Employee-testimonial.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../Hashout-Tech-Employee-testimonial.pdf"></a>
            </object>
        </div>
    )
}

export default HashoutTechEmployeeTestimonial